import Logo from './Logo.png';
import { Config } from '../../interfaces/config.interface';

const config: Config = {
  logo: Logo,
  title: 'Actividades de voluntariado',
  name: 'JCI Mendoza',
};

export default config;
